import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "test_o_mne",
  "path": "/test_o_mne",
  "title": "O mne"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Card = makeShortcode("Card");
const ExperienceSection = makeShortcode("ExperienceSection");
const Section = makeShortcode("Section");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`O mne`}</h1>
    <p>{`Zobrazi tlacitko s textem ve stylu italiky`}</p>
    <p>{` `}<del parentName="p">{`strikethrough`}</del></p>
    <blockquote>
      <p parentName="blockquote">{`blockquote`}</p>
      <blockquote parentName="blockquote">
        <p parentName="blockquote">{`nested blockquote`}</p>
      </blockquote>
      <blockquote parentName="blockquote">
        <p parentName="blockquote"><strong parentName="p">{`I'm bold!`}</strong></p>
      </blockquote>
      <p parentName="blockquote">{`more quotes`}</p>
    </blockquote>
    <blockquote cite="http://www.edwardtufte.com/bboard/q-and-a-fetch-msg?msg_id=0000hB">
  <p>a bunch of paragraphs</p>
  <footer>
    <a href="http://www.edwardtufte.com/bboard/q-and-a-fetch-msg?msg_id=0000hB">Edward Tufte, forum post, ‘Book design: advice and examples’ thread</a>
  </footer>
    </blockquote>
    <ul>
      <li parentName="ul">{`My name is Nick`}</li>
      <li parentName="ul">{`I'm a programmer 🧑‍💻`}</li>
    </ul>
    <h1>{`Section`}</h1>
    <Card variant="secondary" sx={{
      maxWidth: 256
    }} mdxType="Card">
      <span>Card</span>
      <div>
        <blockquote>
          <p parentName="blockquote">{`Nested `}<strong parentName="p">{`MDX`}</strong>{`!`}</p>
        </blockquote>
      </div>
      <ol>
        <li parentName="ol">{`One`}</li>
        <li parentName="ol">{`Two`}</li>
        <li parentName="ol">{`Three`}</li>
      </ol>
    </Card>
    <ul>
      <li parentName="ul">{`My name is Nick`}</li>
      <li parentName="ul">{`I'm a programmer`}</li>
    </ul>
    <h1>{`I'm a programmer`}</h1>
    <h2>{`I'm a programmer`}</h2>
    <h3>{`I'm a programmer`}</h3>
    <h4>{`I'm a programmer`}</h4>
    <h5>{`I'm a programmer`}</h5>
    <h6>{`I'm a programmer`}</h6>
    <ExperienceSection mdxType="ExperienceSection" />
    <Card variant="primary" mdxType="Card">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`This *is* **markdown**!
`}</code></pre>
 </Card>
    <Section mdxType="Section">
      <h1>{`I'm a programmer`}</h1>
      <h2>{`I'm a programmer`}</h2>
      <h3>{`I'm a programmer`}</h3>
      <h4>{`I'm a programmer`}</h4>
      <h5>{`I'm a programmer`}</h5>
      <h6>{`I'm a programmer`}</h6>
      <Card variant="primary" mdxType="Card">
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`This *is* **markdown**!
`}</code></pre>
 </Card>
    </Section>
    <pre><code parentName="pre" {...{
        "className": "language-dart"
      }}>{`import 'dart:async';

import 'package:bloc/bloc.dart';

enum CounterEvent { increment, decrement }

class CounterBloc extends Bloc<CounterEvent, int> {
  @override
  int get initialState => 0;

  @override
  Stream<int> mapEventToState(CounterEvent event) async* {
    switch (event) {
      case CounterEvent.decrement:
        // Simulating Network Latency
        await Future<void>.delayed(Duration(seconds: 1));
        yield state - 1;
        break;
      case CounterEvent.increment:
        // Simulating Network Latency
        await Future<void>.delayed(Duration(milliseconds: 500));
        yield state + 1;
        break;
      default:
        throw Exception('unhandled event: $event');
    }
  }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-docker"
      }}>{`version: '3'
services:
  web:
    build: .
    ports:
      - "5000:5000"
  redis:
    image: "redis:alpine"
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-r"
      }}>{`switch(class(x)[1],
       "class_1" = { ..... },
       "class_2" = { ..... },
       .......,
       .......,
       "class_10" = { ..... },
       stop(" ... invalid class:", class(x)))
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// In your gatsby-config.js
// Let's make this line very long so that our container has to scroll its overflow…
// src/gatsby-plugin-theme-ui/index.js
import nightOwl from '@theme-ui/prism/presets/night-owl';
export default {
  styles: {
    pre: {
      ...nightOwl,
    },
  },
};
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "{1-2,22}{numberLines: true}",
        "{1-2,22}{numberLines:": true,
        "true}": true
      }}>{`// In your gatsby-config.js
// Let's make this line very long so that our container has to scroll its overflow…
{
  plugins: [
    {
      resolve: \`gatsby-transformer-remark\`,
      options: {
        plugins: [
          {
            resolve: \`gatsby-remark-images\`,
            options: {
              maxWidth: 756,
            },
          },
          {
            resolve: \`gatsby-remark-responsive-iframe\`,
            options: {
              wrapperStyle: \`margin-bottom: 1.0725rem\`,
            },
          },
          \`gatsby-remark-copy-linked-files\`,
          \`gatsby-remark-smartypants\`,
          \`gatsby-remark-prismjs\`,
        ],
      },
    },
  ];
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      